import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";

const StyledContainer = styled.div`
  justify-content: flex-start;
  flex-direction: column;
`;

const StyledUserChatBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px 24px;
  @media (max-width: 450px) {
    width: 350px;
  }
  flex-direction: row;
  align-items: center;
`;

const StyledText = styled.span`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: black;
`;
const StyledTextNote = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: black;
`;

const StyledIconContainer = styled.div`
  cursor: pointer;
`;

const StyledLoader = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 12px;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTextLogo = styled.span`
  color: #ffffff;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const StyledImageContainer = styled.div`
  background-color: red;
  height: 20px;
  width: 20px;
  border-radius: 12px;
  border: 1px solid red;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTextUser = styled.span`
  color: black;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

function ChatBoxFile({
  text,
  isFileUploading,
}: {
  text: string;
  isFileUploading: boolean;
}): JSX.Element {
  return (
    <StyledContainer>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <StyledImageContainer>
          <StyledTextLogo>Y</StyledTextLogo>
        </StyledImageContainer>
        <StyledTextUser>You</StyledTextUser>
      </div>
      <StyledUserChatBox>
        {isFileUploading && (
          <StyledLoader>
            <FontAwesomeIcon icon={faSpinner} size="1x" color={"black"} spin />
          </StyledLoader>
        )}
        <StyledIconContainer>
          <FontAwesomeIcon icon={faFileAlt} size="3x" color={"black"} />
        </StyledIconContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          <StyledText>{text}</StyledText>
          <StyledTextNote>
            The context from this document can take upto 5 minutes to propagate
          </StyledTextNote>
        </div>
      </StyledUserChatBox>
    </StyledContainer>
  );
}

export default ChatBoxFile;
