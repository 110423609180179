import React, {
  useState,
  KeyboardEvent,
  useRef,
  ChangeEvent,
  useEffect,
} from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";

import { useConversation } from "shared/modules/mool/hooks/conversation";
import { useFileUpload } from "shared/modules/capitalmind/hooks/file-upload";
import Placeholder from "./components/placeholder";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPaperclip,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import FileModal from "shared/components/context-modal/file-modal";
import PreferenceModal from "../preference/preference-modal";

import MoolLogo from "theme/assets/images/logo-white.png";
import ChatBoxFile from "./components/chat-box-file";
import SlackModal from "shared/components/slack-modal";
import EmailModal from "shared/components/email-modal";
import ChatBoxAi from "shared/components/chat-box-ai";
import ActionButton from "shared/components/action-button";
import ChatBoxAiWeb from "shared/components/chat-box-ai-web";
import ChatBoxUser from "shared/components/chat-box-user";

const FILE_UPLOAD_LIMIT = 25;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #ffffff;
`;

const StyledBanner = styled.div`
  background-color: #024584;
  justify-content: center;
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
`;

const StyledFooter = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-top: 0.5px solid #d9dfe9;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  z-index;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledIconContainer = styled.div`
  padding: 12px 0px 12px 16px;
  cursor: pointer;
`;

const StyledIconContainerLeft = styled.div`
  padding: 12px 16px 12px 0px;
  cursor: pointer;
`;

const StyledInputContainer = styled.div`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background: #eff2f6;
  width: 100%;
  max-width: 812px;
`;

const StyledFooterText = styled.span`
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
  text-align: center;
  color: #a0aabd;
`;

const StyledTextInput = styled.input`
  font-family: Poppins;
  background-color: transparent;
  border: 0px;
  width: 100%;
  outline: none;
  font-size: 16px;
`;

const StyledChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 82vh;
  overflow: scroll;
  margin-bottom: 120px;
  padding-bottom: 24px;
  background-color: #f1f3f9;
`;

const IconContainerLeft = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const IconContainerRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

function ChatModule(): JSX.Element {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);

  const { fileUploadHandler, isLoading: isFileUploading } = useFileUpload({
    source: "mool-capital",
  });

  const {
    handleConversation,
    conversations,
    isLoading,
    total,
    handleFileUpload,
    webSearchHandler,
    googleDocsAppendHandler,
    isWebSearchLoading,
    webSearchQuery,
    isGoogleDocsLoading,
  } = useConversation("mool-capital");
  const [query, setQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreferenceModalOpen, setIsPreferenceModalOpen] = useState(false);
  const [isSlackModalOpen, setIsSlackModalOpen] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const onKeyDown = (keyEvent: KeyboardEvent<HTMLInputElement>) => {
    if (keyEvent.key === "Enter" && query) {
      handleConversation(query);
      setQuery("");
    }
  };

  const handleFileLimitReached = () => {
    toast.error(
      `Only ${FILE_UPLOAD_LIMIT} files are allowed at a time. Please try again.`
    );
  };

  const onClickHandler = () => {
    if (query && !isFileUploading && !isLoading) {
      handleConversation(query);
      setQuery("");
    }
  };

  const handleClick = () => {
    if (hiddenFileInput?.current && !isLoading && !isFileUploading) {
      hiddenFileInput.current.click();
    }
  };

  const handleFile = (files: File[]) => {
    if (!isLoading && !isFileUploading) {
      fileUploadHandler({ files, source: "mool-capital" });
      files.forEach((file) => {
        handleFileUpload(`Document upload : ${file.name}.`);
      });
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const targetFiles = target.files as FileList;
    const files = [];
    const fileLength = targetFiles?.length as number;
    if (fileLength && fileLength <= FILE_UPLOAD_LIMIT) {
      for (let i = 0; i < fileLength; i++) {
        files.push(targetFiles[i]);
      }
    } else {
      handleFileLimitReached();
    }
    if (files.length > 0) {
      handleFile(files);
    }
  };

  useEffect(() => {
    if (conversations.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [conversations.length]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closePreferenceModal = () => {
    setIsPreferenceModalOpen(false);
  };

  const openSlackModal = () => {
    setIsSlackModalOpen(true);
  };
  const closeSlackModal = () => {
    setIsSlackModalOpen(false);
  };

  const openEmailModal = () => {
    setIsEmailModalOpen(true);
  };
  const closeEmailModal = () => {
    setIsEmailModalOpen(false);
  };

  return (
    <Container>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <FileModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        source={"mool-capital"}
      />
      <PreferenceModal
        isModalOpen={isPreferenceModalOpen}
        closeModal={closePreferenceModal}
      />
      <SlackModal
        isModalOpen={isSlackModalOpen}
        closeModal={closeSlackModal}
        conversations={conversations}
      />
      <EmailModal
        isModalOpen={isEmailModalOpen}
        closeModal={closeEmailModal}
        conversations={conversations}
      />
      <StyledBanner>
        <IconContainerLeft onClick={() => setIsModalOpen(true)}>
          <FontAwesomeIcon icon={faFolderOpen} size="lg" color={"#ffffff"} />
        </IconContainerLeft>
        <LogoContainer>
          <img src={MoolLogo} style={{ height: 48 }} alt="MoolLogo" />
        </LogoContainer>
        <IconContainerRight>
          {/* <FontAwesomeIcon icon={faGear} size="lg" color={"#ffffff"} /> */}
        </IconContainerRight>
      </StyledBanner>
      <StyledChatContainer>
        {!total && !isLoading && <Placeholder />}
        <div style={{ margin: "24px 0px" }}>
          {conversations.map((conversation, index, array) => {
            return (
              <div
                key={index}
                style={{
                  padding: "16px 64px",
                  backgroundColor:
                    conversation.type === "ai" || conversation.type === "ai-web"
                      ? "#EAEAEA"
                      : "transparent",
                }}
              >
                {conversation.type === "ai" ? (
                  <>
                    <ChatBoxAi
                      text={conversation.text}
                      sqlQuery={conversation.sqlQuery}
                      documentSources={conversation.documentSources}
                      label={"Mool"}
                    />
                    {index === array.length - 1 && !isLoading && (
                      <div
                        style={{
                          padding: "12px 24px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <ActionButton
                          title="Search Web"
                          onClickHandler={webSearchHandler}
                          icon="web"
                        />
                        <ActionButton
                          title="Slack"
                          onClickHandler={openSlackModal}
                          icon="slack"
                        />
                        <ActionButton
                          title="Email"
                          onClickHandler={openEmailModal}
                          icon="email"
                        />
                        <ActionButton
                          title="Google Docs"
                          onClickHandler={googleDocsAppendHandler}
                          icon="google-docs"
                          isLoading={isGoogleDocsLoading}
                        />
                      </div>
                    )}
                  </>
                ) : conversation.type === "ai-web" ? (
                  <>
                    <ChatBoxAiWeb
                      text={conversation.text}
                      sources={conversation?.sources || []}
                    />
                    {index === array.length - 1 && !isLoading && (
                      <div
                        style={{
                          padding: "12px 24px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <ActionButton
                          title="Slack"
                          onClickHandler={openSlackModal}
                          icon="slack"
                        />
                        <ActionButton
                          title="Email"
                          onClickHandler={openEmailModal}
                          icon="email"
                        />
                        <ActionButton
                          title="Google Docs"
                          onClickHandler={googleDocsAppendHandler}
                          icon="google-docs"
                          isLoading={isGoogleDocsLoading}
                        />
                      </div>
                    )}
                  </>
                ) : conversation.type === "user" ? (
                  <ChatBoxUser text={conversation.text} />
                ) : conversation.type === "file" ? (
                  <ChatBoxFile
                    isFileUploading={
                      isFileUploading && index === array.length - 1
                    }
                    text={conversation.text}
                  />
                ) : (
                  <></>
                )}
              </div>
            );
          })}
          {isLoading && (
            <div style={{ padding: "16px 64px", backgroundColor: "#EAEAEA" }}>
              <ChatBoxAi
                label={"Mool"}
                text={webSearchQuery}
                isLoading
                isWebSearchLoading={isWebSearchLoading}
              />
            </div>
          )}
        </div>
        <div ref={ref} />
      </StyledChatContainer>
      <StyledFooter>
        <StyledInputWrapper>
          <StyledIconContainerLeft onClick={handleClick}>
            <FontAwesomeIcon
              icon={faPaperclip}
              size="xl"
              color={isFileUploading || isLoading ? "#a0aabd" : "#024584"}
            />
            <input
              ref={hiddenFileInput}
              onChange={handleChange}
              hidden
              type="file"
              accept=".pdf, .csv, .ppt, .pptx, .doc, .docx, .txt"
              multiple
            />
          </StyledIconContainerLeft>
          <StyledInputContainer>
            <StyledTextInput
              placeholder="Ask a question"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyDown={onKeyDown}
              disabled={isLoading}
            />
          </StyledInputContainer>
          <StyledIconContainer onClick={onClickHandler}>
            <FontAwesomeIcon
              icon={faPaperPlane}
              size="xl"
              color={
                isFileUploading || isLoading || !query ? "#a0aabd" : "#881946"
              }
            />
          </StyledIconContainer>
        </StyledInputWrapper>
        <StyledFooterText>
          Powered by{" "}
          <a href="https://mool.ai" target="blank">
            <span style={{ color: "#024584" }}>Mool.ai</span>
          </a>
          . This tool is meant for education and demonstration only. All logos,
          trademarks, and reference content are the sole property of Mool
          Capital.
        </StyledFooterText>
      </StyledFooter>
    </Container>
  );
}

export default ChatModule;
